.toolbar {
  justify-content: space-between;
  width: 100%;

  border-radius: 10px;
}

.profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.userName {
  justify-content: space-between;
  width: 100%;
  margin-left: "10px";
}
